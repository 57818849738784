import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="We offer impactful mobile app development services to bring your project to market on every device and platform. Talk to our mobile app experts today!"
        />
        <meta name="author" content="https://capdigisoft.com" />
        <meta name="robots" content="index, follow" />

        <link
          rel="alternate"
          hreflang="x-default"
          href="https://softwareappdevelopment.capdigisoft.com"
        />

        <link
          rel="canonical"
          href="https://softwareappdevelopment.capdigisoft.com/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Mobile App Development Company - Build Mobile Apps With Us"
        />
        <meta
          property="og:description"
          content="We offer impactful mobile app development services to bring your project to market on every device and platform. Talk to our mobile app experts today!"
        />
        <meta
          property="og:url"
          content="https://softwareappdevelopment.capdigisoft.com/"
        />
        <meta property="og:site_name" content="CAP Digisoft" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/capdigisoftsolutions/"
        />
        <meta
          property="article:modified_time"
          content="2021-08-23T14:13:42+00:00"
        />
        <meta
          property="og:image"
          content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mobile App Development Company - Build Mobile Apps With Us"
        />
        <meta
          name="twitter:description"
          content="We offer impactful mobile app development services to bring your project to market on every device and platform. Talk to our mobile app experts today!"
        />
        <meta
          name="twitter:image"
          content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
        />
        <meta name="twitter:site" content="@CAPDigisoft" />

        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "capdigisoft",
  "url": "https://capdigisoft.com/",
  "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
  "sameAs": [
    "https://www.facebook.com/capdigisoftsolutions/",
    "https://x.com/CAPDigisoft",
    "@capdigisoftsolutions",
    "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
    "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
  ]
}`}
        </script>

        <script type="application/ld+json">
          {`{
	"@context": "http://schema.org/",
	"@type": "BreadcrumbList",
	"itemListElement": [
		{
			"@type": "ListItem",
			"position": 1,
			"name": "Capdigisoft",
			"item": "https://capdigisoft.com"
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "softwareappdevelopment",
			"item": "https://softwareappdevelopment.capdigisoft.com/"
		}
	]
}`}
        </script>
      </Helmet>
    </>
  );
};

export default Meta;


import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";

// idea to product import
import MobileSoftwareDevelopment from "./components/pages/Mobile-Software-Development/index.jsx";

//form import
import BookMeeting from "./components/pages/Form/bookmeeting";
//thank you
import ThankYouPage from "./components/pages/Thank/ThankYou";
import ThankDownload from "./components/pages/Thank/ThankYouDownload";

//Google Analytics
import Ganalytics from './Ganalytics';
//Meta
import Meta from './Meta';

function App() {
  return (
    <BrowserRouter basename='/'>
      <Ganalytics/>
      <Meta/>
     
      <Routes>
        {/* Cap Digisoft Main Pages (this import will load the page on initial server run) */}
        <Route exact path="/" element={<MobileSoftwareDevelopment />}/>

        {/* Solutions Pages Links */}
        <Route path="/mobile-software-development" element={<MobileSoftwareDevelopment />}/>

        {/* form route */}
        <Route path="/form" element={<BookMeeting />}/>

        {/* thankyou route */}
        <Route path="/thankyou" element={<ThankYouPage />}/>
        <Route path="/thankyoudownload" element={<ThankDownload />}/>
      </Routes>

    </BrowserRouter>
  );
}

export default App;

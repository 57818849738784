import React, {Suspense} from "react";

const BannerCnt = () => {
  return (
    <>
      <div className="col-lg-7 col-md-12 mb-2 pe-lg-5">
        <h1 className="hero-heading">
          Custom Software & Mobile App Development
        </h1>
        <Suspense fallback={<div className="loading"></div>}>
        <div className="banner-casualties">
          <ul className="ps-0 list-unstyled">
            <li className="h4">
              Expertise in Custom Software and Mobile Applications
            </li>
            <li>
              We developed hundreds of custom software and mobile applications
              for organizations ranging from startups to Fortune 500 companies.
            </li>
          </ul>
          <ul className="ps-0 list-unstyled">
            <li className="h4">Technology Selection</li>
            <li>Identified the right technology for each client's needs.</li>
          </ul>
          <ul className="ps-0 list-unstyled">
            <li className="h4">Incremental Development Methodologies</li>
            <li>
              Employed incremental development methodologies for software
              applications. Enabled clients to observe and start using the
              software at the earliest stages of its development.
            </li>
          </ul>
        </div>
        </Suspense>
      </div>
    </>
  );
};

export default BannerCnt;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LogoDark from "../../app/assets/images/logo-dark.png";
import BookMeeting from "../../components/pages/Form/bookmeeting";
import $ from "jquery";

const HeaderOne = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      const windowTop = $(window).scrollTop() + 1;
      if (windowTop > 50) {
        $(".header-navbar").addClass("menu_fixed animated fadeInDown");
      } else {
        $(".header-navbar").removeClass("menu_fixed animated fadeInDown");
      }
    };

    window.addEventListener("resize", handleResize);
    $(window).on("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      $(window).off("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className="header-style-1">
        <div
          className={`header-navbar navbar-sticky custom-dark-header ${
            windowWidth <= 1198.98 ? "mobile-menu" : ""
          }`}
        >
          <div className="container custom-cn-width">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center nav-space pdt-15 pdb-12 cp-min-pd">
                  <div className="site-logo text-center">
                    <Link to="https://capdigisoft.com/">
                      <img
                        width={230}
                        src={LogoDark}
                        alt="Logo"
                        className="img-fluid lg-display-dark"
                      />
                    </Link>
                  </div>

                  <div
                    className="header-btn d-sm-block d-none"
                    style={{ marginTop: "-4px" }}
                  >
                    <button
                      type="button"
                      className="btn cust-btn-primary secondary-main"
                      data-bs-toggle="modal"
                      data-bs-target="#meeting"
                    >
                      BOOK A MEETING
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className="modal fade"
        id="meeting"
        tabindex="-1"
        aria-labelledby="apply-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Book a meeting</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <BookMeeting />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderOne;

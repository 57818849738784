import React, { Suspense, lazy } from "react";
import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";
import Banner from "./SubComponents/banner";

// Lazy load the page components
const SectionOne = lazy(() => import("./SubComponents/sectionone"));
const SectionTwo = lazy(() => import("./SubComponents/sectiontwo"));
const SectionThree = lazy(() => import("./SubComponents/sectionthree"));
const SectionFour = lazy(() => import("./SubComponents/sectionfour"));
const CallToAction = lazy(() => import("./SubComponents/calltoaction"));
const BrandSlider = lazy(() => import("./SubComponents/brandslider"));
const FreeDownload = lazy(() => import("./SubComponents/freedownload"));
const OtherSolutions = lazy(() => import("./SubComponents/othersolutions"));

const MobileSoftwareDevelopment = () => {
  return (
    <>
      <HeaderDark />
      <div className="mobile-software_development-page">
        <Banner />
        <Suspense fallback={<div className="loading"></div>}>
          <SectionOne />
          <SectionTwo />
          <SectionFour />
          <SectionThree />
          <CallToAction />
          <BrandSlider />
          <FreeDownload />
          <OtherSolutions />
        </Suspense>
      </div>
      <FooterOne />

      <style jsx>{`
        .loading {
          padding: 120px;
          height: 200px;
        }
        .banner-sec p.para,
        p,
        ul li {
          font-weight: 400;
          letter-spacing: normal;
          line-height: 1.4;
          margin-bottom: 0;
        }

        .idp-head,
        .kpo-head,
        .mls-card h6,
        .service_title,
        .timeline .year,
        .timeline-heads,
        .title,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 600;
        }
        .fw-400 {
          font-weight: 400 !important;
        }
        .cust-btn-primary {
          background-color: #0c5b79 !important;
          padding: 18px 26px;
          color: #fff;
          font-size: 15px;
          font-weight: 700;
          height: auto;
          line-height: 1;
          margin: 0;
          border-radius: 6px;
          letter-spacing: -0.1px;
          border: 0;
        }
        .cust-btn-primary,
        legend {
          text-transform: uppercase;
          width: auto;
        }
      `}</style>
    </>
  );
};

export default MobileSoftwareDevelopment;
